exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-tools-heic-to-jpeg-converter-js": () => import("./../../../src/pages/tools/heic-to-jpeg-converter.js" /* webpackChunkName: "component---src-pages-tools-heic-to-jpeg-converter-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tools-svg-to-jpeg-png-converter-js": () => import("./../../../src/pages/tools/svg-to-jpeg-png-converter.js" /* webpackChunkName: "component---src-pages-tools-svg-to-jpeg-png-converter-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blog-post-template.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-tag-list-template-js": () => import("./../../../src/templates/tag-list-template.js" /* webpackChunkName: "component---src-templates-tag-list-template-js" */),
  "component---src-templates-tag-posts-template-js": () => import("./../../../src/templates/tag-posts-template.js" /* webpackChunkName: "component---src-templates-tag-posts-template-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

